.name:hover {
	cursor: pointer;
}

.name span {
	font-size: 10vw;
}

.title {
	display: inline;
}

.title span {
	font-size: 3vw;
	position: relative;
	bottom: 1vw;
	left: 4px;
}

.title.blue span {
	color: powderblue;
}

@media only screen and (max-width: 600px) {
	.title span {
		font-size: 5vw;
		left: 2px;
	}
}
