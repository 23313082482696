body {
  margin: 0;
  padding: 0;
  font-family: Calibri, "Trebuchet MS", sans-serif;
  color: rgb(76, 76, 76);
}

a {
  color: inherit;
  text-decoration: none;
}
