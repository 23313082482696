.cv-wrapper {
	display: flex;
}

@media only screen and (max-width: 600px) {
	.cv-wrapper {
		flex-flow: column;
		font-size: 16px;
	}
}
