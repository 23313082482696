.contact {
	display: flex;
	position: relative;
	justify-content: space-around;
	font-size: 2vw;
	height: 100px;
	align-items: center;
	bottom: 20px;
	border-top: solid;
	margin-top: 10vh;
}

@media only screen and (max-width: 600px) {
	.contact {
		font-size: 16px;
		height: 75px;
		padding-bottom: 30px;
		bottom: 0px;
	}
}
