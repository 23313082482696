.app-container {
	width: 90%;
	margin: auto;
}

.content-container {
	font-family: "Gill Sans";
	font-size: 18px;
	font-weight: 300;
	letter-spacing: 0.6px;
	line-height: 1.3;
	max-width: 1100px;
	margin: auto;
	opacity: 0;
	animation: fadeIn 1s ease 2.95s forwards;
	-webkit-animation: fadeIn 1s ease 2.95s forwards;
	-moz-animation: fadeIn 1s ease 2.95s forwards;
	-o-animation: fadeIn 1s ease 2.95s forwards;
	-ms-animation: fadeIn 1s ease 2.95s forwards;
}

.left {
	flex: 2;
	display: flex;
	flex-direction: column;
}

.right {
	flex: 4;
	display: flex;
	flex-direction: column;
}

.experience {
	border: solid thick yellowgreen;
}

.education {
	border: solid thick powderblue;
}

.about {
	border: solid thick tomato;
}

.hoverable:hover {
	cursor: pointer;
}

.section-title {
	font-size: 40px;
	margin: 10px 0;
	font-weight: 600;
}

.section-heading {
	font-size: 20px;
}

.tile {
	padding: 15px 30px;
	flex: 1;
	border-radius: 5px;
	margin: 10px;
}

.bold {
	font-weight: 600;
}

.italic {
	font-style: italic;
}

@media only screen and (max-width: 600px) {
	ul {
		padding-inline-start: 16px;
	}

	.header {
		margin: 20px 0 15px;
	}

	.name {
		padding-bottom: 4vw !important;
	}

	.section-title {
		font-size: 22px;
		margin: 6px 0;
	}

	.section-heading {
		font-size: 18px;
	}

	.text {
		font-size: 26px !important;
	}

	.experience {
		border: solid thin yellowgreen;
	}

	.education {
		border: solid thin powderblue;
	}

	.about {
		border: solid thin tomato;
	}

	.tile {
		padding: 5px 20px 15px;
		margin: 5px;
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-moz-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-webkit-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-o-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-ms-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
